import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import DynamicTable from "../components/UI/DynamicTable/DynamicTable"
import BreadCrumb from "../components/BreadCrumb/BreadCrumb"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import AccordionMenu from "../components/UI/AccordianMenu/AccordionMenu"
import ImageCardsLayout from "../components/ImageCardsLayout/ImageCardsLayout"
import { imageConstants } from "../constants/image.constants"
import { gaInternalLink} from '../constants/gtm.constants'
import {
  PRODUCT_DETAILS_ACCORDIONS,
  PRODUCT_DETAILS_LABELS,
} from "../constants/productDetails.constants"
import ArticleVideo from "../components/ArticleVideo/ArticleVideo"
import { displayProductImage } from "../helpers/utils"
import useScreenSize from "../hooks/useScreenSize";
import ProductCTABlock from '../components/CtaBlock/CtaBlock'
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const ProductDetails = props => {
  const { pageContext } = props
  const {
    seoData,
    globalComponents,
    productData,
    dataLayer,
    contentfulProps,
    apiData,
    pageType,
  } = pageContext

  const screenSize = useScreenSize();
  const { products } = apiData || { products: [] }
  const { NutritionalTable, SiteCoreContent } = productData || {}
  const [accordionTitle1, accordionTitle2] = PRODUCT_DETAILS_ACCORDIONS
  const [ImageHeroBannerProps, BreadCrumbProps, ArticleVideoProps, CtaBlock] =
    contentfulProps || []
  const productDataProp =
    ImageHeroBannerProps &&
    buildHeroBannerData(ImageHeroBannerProps?.ImageHeroBanner)
  const { items: navigationCollectionItems } = BreadCrumbProps?.BreadCrumb
    ?.navigationCollection || { items: [] }

  if (productDataProp?.images) {
    productDataProp.images.altText = productDataProp?.heading
  }

  if (navigationCollectionItems && navigationCollectionItems[0]) {
    if (navigationCollectionItems[0]?.link?.url) {
      navigationCollectionItems[0].link.url = productData?.pageSlug
    }
  }

  const breadCrumbItems = {
    items: [
      ...navigationCollectionItems,
      {
        link: {
          title: productData?.ProductName || productData?.ProductTitle,
          url: productData?.pageSlug,
        },
      },
    ],
  }

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: productData?.ProductName || productData?.ProductTitle || 
        seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: "Product Detail Page",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  // Building the hero banner component data
  function buildHeroBannerData(data) {
    if (data) {
      data.heading = productData?.ProductName || productData?.ProductTitle
      data.description = productData?.ProductLongDescription || ""
      data.images = {
        imageDesktop : displayProductImage(productData?.ProductImage, screenSize?.isMobile ? "Mobile" : "Desktop"),
      }
    }
    return data
  }

  const getRelatedProducts = () => {
    return `${productData?.RelatedProducts}`
      .split(",")
      .map(pId => {
        const m = products.find(f => f?.ProductID === pId)
        if (m) {
          const title = m?.ProductTitle || m?.SeoPageName
          return {
            link: {
              url: m?.pageSlug,
              gaEventLabel: title
            },
            image: displayProductImage(m?.ProductImage, "Mobile"),
            images: {
              altText: title
            },
            title,
            variant: "RELATED_PRODUCTS_CARD",
            gaEventClass: gaInternalLink,
            gaEventLabel: title
          }
        }
        return null
      })
      .filter(f => f)
  }

  const parseTags = (value) => {
    if (value?.indexOf('<br') >= 0) {
      return <div className="ntable_html" dangerouslySetInnerHTML={{__html: value}} />
    }
    return value
  }

  const getNutritionTable = () => {
    const rowHeading = (NutritionalTable || []).reduce(
      (a, b) => {
        a.push(b?.title)
        return a
      },
      [PRODUCT_DETAILS_LABELS.INGREDIENTS]
    )
    let rowData = (NutritionalTable || []).map(m => {
      m.rowData.map(r => (r.title = m.title))
      return m.rowData
    })
      .flat()
      .reduce((a, b) => {
        if (!a[b?.value]) {
          a[b?.value] = {
            [rowHeading[0]]: parseTags(b?.value),
            index: b?.id,
          }
        }
        a[b?.value] = {
          ...a[b?.value],
          [b?.title]: parseTags(b?.serving),
        }
        return a
      }, {})
    rowData = Object.values(rowData)
      .map(m => {
        delete m.index
        return m
      })
      .sort((a, b) => a.index - b.index)
    return {
      rowHeading,
      rowData,
      hasNutritionData: rowData.length > 0,
    }
  }

  const renderNutritionAccordion = () => {
    const { hasNutritionData, ...nutritionTableData } = getNutritionTable()
    return hasNutritionData ? (
      <AccordionMenu title={accordionTitle1} variant={"PRODUCT_DETAIL"}>
        <div className="lg:flex justify-between pt-[26px]">
          <div className="w-full lg:w-[30%] text-purple text-[18px] leading-[23.5px] lg:text-[20px] lg:leading-[26px]">
            <div
              dangerouslySetInnerHTML={{
                __html: productData?.ProductIngredients,
              }}
            />
          </div>
          <div className="w-full lg:w-[56%] pt-20 lg:pt-0">
            <DynamicTable {...nutritionTableData} variant={"product_detail_table"}/>
          </div>
        </div>
      </AccordionMenu>
    ) : null
  }

  const renderSiteCoreContent = () => {
    return SiteCoreContent ? (
      <AccordionMenu title={accordionTitle2} variant={"PRODUCT_DETAIL"}>
        <div className="lg:flex justify-between pt-[26px]">
          <div className="w-full text-purple text-[20px] leading-[26px]">
            <div
              dangerouslySetInnerHTML={{
                __html: SiteCoreContent,
              }}
            />
          </div>
        </div>
      </AccordionMenu>
    ) : null
  }

  return (
    <div>
      <Layout
        seoData={{
          title: productData?.ProductName || productData?.ProductTitle,
          metaDescription: productData?.MetaDescription
        }}
        globalComponents={globalComponents} pageType={pageType}
        headerVariant={"TEXT_BLUE"}
      >
        <div
          className="flex flex-col pt-[75px] min-[480px]:pt-[150px]"
          style={{
            backgroundImage: `url(${imageConstants.productDetailBg.url})`,
          }}
        >
          <div className="flex flex-col items-center bg-no-repeat bg-bottom">
            <div className="w-full lg:max-w-[1170px] mx-auto productDetailWrap">
              <ImageWithText
                {...productDataProp}
                showDefault={true}
                breadCrumbs={
                  <BreadCrumb
                    variant={"PRODUCT_DETAIL"}
                    navigationCollection={breadCrumbItems}
                  />
                }
                variant={"PRODUCT_DETAILS_PAGE"}
              />
            </div>
            <div className="w-[calc(100%-30px)] lg:max-w-[1170px] mx-auto my-[25px] productAccordion">
              {renderNutritionAccordion()}
              {renderSiteCoreContent()}
            </div>            
            <ImageCardsLayout
              title={"Unsere beliebten Klassiker"}
              cardsCollection={{
                items: getRelatedProducts(),
              }}
              variant={"RELATED_PRODUCTS"}
            />            
          </div>
        </div>
        <div>
          <ArticleVideo {...ArticleVideoProps?.ArticleVideo}></ArticleVideo>
        </div>
        <div><ProductCTABlock {...CtaBlock?.CtaBlock} /></div>
      </Layout>
    </div>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
    </>
  )
}

ProductDetails.propTypes = {
  pageContext: PropTypes.shape({
    productData: PropTypes.object,
    contentfulData: PropTypes.array,
    prdSlug: PropTypes.string,
    pageType: PropTypes.string,
    NutritionalTable: PropTypes.array,
    dataLayer: PropTypes.object,
    globalComponents: PropTypes.array,
    seoData: PropTypes.object,
    contentfulProps: PropTypes.array,
    apiData: PropTypes.object,
  }),
}

export default ProductDetails